import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Hook
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

const UserGuide = () => {
  const { t, i18n } = useTranslation(["legal"]);

  const { lang } = useParams();

  useEffect(() => {
    i18n.changeLanguage(lang || "en");
  }, [lang, i18n]);

  const { width, height } = useWindowSize();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "auto",
        "-webkit-overflow-scrolling": "touch",
      }}
    >
      <iframe
        src={
          lang === "fr"
            ? "https://drive.google.com/file/d/1PBLkoRb8CMMQ7FXB64q3yz1WldTAFxoQ/preview"
            : "https://drive.google.com/file/d/1PBLkoRb8CMMQ7FXB64q3yz1WldTAFxoQ/preview"
        }
        width={width}
        height={height}
        allow="autoplay"
        title="user-guide"
      ></iframe>
    </div>
  );
};

export default UserGuide;
